export class CapsuleType {
  id: string;
  name: string;

  capsuleApiKey: string;
  description: string;
  imageUrl: string;

  beta?: boolean;
}

export enum WordpressDeploymentType {
  default = 'default',
  git = 'git',
}

export enum WordpressVersion {
  v65 = '6.5',
  v66 = '6.6',
  v67 = '6.7',
}
