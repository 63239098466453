import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
} from '@angular/core';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';

import { Plan } from '../../models/plan';
import { ClusterScaleConfig } from '../../models/cluster-scale-config';
import { CapsulePricingService } from '../../services/capsule-pricing.service';
import { Cluster, CapsuleType } from '../../models';
import { Team } from '../../models';
import { UsageMarkupService } from '../../services/usage-markup.service';

@Component({
  selector: 'app-capsule-plan',
  templateUrl: './capsule-plan.component.html',
  styleUrls: ['./capsule-plan.component.scss'],
})
export class CapsulePlanComponent implements OnChanges {
  clusterScaleConfig: ClusterScaleConfig;

  @Input() showTitle: boolean = true;
  @Input() team: Team;
  @Input() plan: Plan;
  @Input() plans: Plan[];
  @Output() planChange = new EventEmitter<Plan>();

  @Input() loading: boolean = false;
  @Input() cluster: Cluster;
  @Input() capsuleType: CapsuleType;

  isCustom = false;
  customPlan?: Plan;

  cpuSliderOptions: Options = {
    floor: 0,
    ceil: 4,
    step: 0.1,
    translate: (value: number) => `${Number(value * 100).toFixed(0)}% CPU`,
  };

  ramSliderOptions: Options = {
    floor: 0,
    ceil: 16,
    step: 0.2,
    translate: (value: number) => `${value} GB`,
  };

  gpuSliderOptions: Options = {
    floor: 0,
    ceil: 4,
    step: 1,
    translate: (value: number) => `${value} GPU`,
  };

  storageSliderOptions: Options = {
    floor: 0,
    ceil: 1000,
    stepsArray: [
      { value: 1 },
      { value: 2 },
      { value: 5 },
      { value: 10 },
      { value: 15 },
      { value: 30 },
      { value: 50 },
      { value: 100 },
      { value: 200 },
      { value: 500 },
      { value: 1000 },
    ],
    translate: (value: number) => `${value} GB`,
  };

  replicasSliderOptions: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    translate: (value: number) => `${value}`,
  };

  constructor(
    private pricing: CapsulePricingService,
    private markupService: UsageMarkupService
  ) {}

  ngOnChanges(): void {
    if (this.capsuleType && this.cluster && !this.clusterScaleConfig) {
      this.populateClusterScaleConfig();
    }

    if (!this.plan && this.plans.length) {
      this.onPlanSelected(this.plans[0]);
    } else if (!this.plan && this.customPlan) {
      this.onPlanSelected(this.customPlan);
    } else {
      this.isCustom = !this.plan.id;
    }
  }

  private populateClusterScaleConfig() {
    const capsuleType = this.capsuleType.id;
    const scaleConfigByCapsuleType =
      this.cluster.scaleConfigByCapsuleType ?? {};
    const clusterScaleConfig = scaleConfigByCapsuleType[capsuleType];
    if (!clusterScaleConfig) return;
    this.clusterScaleConfig = clusterScaleConfig;

    this.cpuSliderOptions = {
      floor: clusterScaleConfig.cpuMin,
      ceil: clusterScaleConfig.cpuMax,
      step: 0.05,
      translate: (value: number) => `${Number(value * 100).toFixed(0)}% CPU`,
    };

    this.ramSliderOptions = {
      floor: clusterScaleConfig.ramMin,
      ceil: clusterScaleConfig.ramMax,
      step: 0.2,
      translate: (value: number) => `${value} GB`,
    };

    this.gpuSliderOptions = {
      floor: clusterScaleConfig.gpuMin,
      ceil: clusterScaleConfig.gpuMax,
      step: 1,
      translate: (value: number) => `${value} GPU`,
    };

    this.storageSliderOptions = {
      floor: clusterScaleConfig.storageMin,
      ceil: clusterScaleConfig.storageMax,
      // step: 1,
      stepsArray: [
        { value: 1 },
        { value: 2 },
        { value: 5 },
        { value: 10 },
        { value: 15 },
        { value: 30 },
        { value: 50 },
        { value: 100 },
        { value: 200 },
        { value: 500 },
        { value: 1000 },
      ],
      translate: (value: number) => `${value} GB`,
    };

    this.replicasSliderOptions = {
      floor: clusterScaleConfig.replicasMin,
      ceil: clusterScaleConfig.replicasMax,
      step: 1,
      translate: (value: number) => `${value}`,
    };

    this.customPlan = {
      capsuleType: clusterScaleConfig.capsuleType,
      clusterId: clusterScaleConfig.clusterId,
      cpu: clusterScaleConfig.cpuMin,
      ram: clusterScaleConfig.ramMin,
      storage: clusterScaleConfig.storageMin,
      gpu: clusterScaleConfig.gpuMin,
      replicas: clusterScaleConfig.replicasMin,
    };
  }

  public calculatePlanMarkedUpPrice(plan: Plan): string {
    if (!this.cluster?.pricing) return '';
    const clusterPricing = this.cluster?.pricing;
    const price = this.pricing.calculateCapsulePrice(clusterPricing, plan);
    return this.markupService.applyMarkupForTeam(price, this.team).toFixed(2);
  }

  public onPlanSelected(plan: Plan): void {
    if (this.isCustom && !plan.id) return;
    this.plan = plan;
    this.isCustom = !plan.id;
    this.planChange.emit(plan);
  }

  public onCpuChange(change: ChangeContext): void {
    this.plan.cpu = change.value;
    this.plan.ram = change.value * 4;
    this.planChange.emit(this.plan);
  }

  public onRamChange(change: ChangeContext): void {
    this.plan.ram = change.value;
    this.plan.cpu = change.value / 4;
    this.planChange.emit(this.plan);
  }

  onPlanValueChange(): void {
    this.planChange.emit(this.plan);
  }
}
