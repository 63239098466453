// angular
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() public placement: 'top' | 'bottom' | 'left' | 'right' | 'auto' = 'auto';
}
