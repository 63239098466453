import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-php-ini-editor',
  templateUrl: './php-ini-editor.component.html',
  styleUrls: ['./php-ini-editor.component.scss'],
})
export class PhpIniEditorComponent {
  @Input() customIni: string;
  @Output() customIniChange = new EventEmitter<string>();

  public editorOptions = {
    language: 'dotenv',
    minimap: { enabled: false },
    wordWrap: 'off',
  };

  public onCustomIniChange(): void {
    this.customIniChange.emit(this.customIni);
  }
}
