<div class="capsule-plan">
  <div
    *ngIf="(plans?.length || clusterScaleConfig) && showTitle"
    class="font-18 bold">
    Choose a plan
  </div>

  <div *ngIf="!plans?.length && !clusterScaleConfig" class="font-18 bold">
    No plans available
  </div>

  <div
    *ngIf="loading"
    class="d-flex justify-content-center align-items-center h-75">
    <app-spinner color="#ffdf00" size="1.5rem" class="mx-1"></app-spinner>
  </div>

  <div
    *ngFor="let planOpt of plans"
    [class.active]="plan?.id === planOpt.id"
    (click)="onPlanSelected(planOpt)"
    class="plan d-flex align-items-center"
    data-testid="capsule-plan-options">
    <div class="radio">
      <div class="radio-inner"></div>
    </div>

    <div class="ms-3 d-flex justify-content-between w-100">
      <div>
        <h5 class="m-0">{{ planOpt.name }}</h5>

        <div>
          <span
            *ngIf="clusterScaleConfig?.cpu && planOpt.cpu"
            class="plan-badge"
            >{{ planOpt.cpu * 100 | number : '1.0-2' }}% CPU</span
          >
          <span
            *ngIf="clusterScaleConfig?.ram && planOpt.ram"
            class="plan-badge"
            >{{ planOpt.ram | number : '1.0-2' }} GB RAM</span
          >
          <span
            *ngIf="clusterScaleConfig?.gpu && planOpt.gpu"
            class="plan-badge"
            >{{ planOpt.gpu | number : '1.0' }} GPU</span
          >
          <span
            *ngIf="clusterScaleConfig?.storage && planOpt.storage"
            class="plan-badge"
            >{{ planOpt.storage | number : '1.0' }} GB Storage</span
          >
          <span
            *ngIf="clusterScaleConfig?.replicas && planOpt.replicas"
            class="plan-badge"
            >{{ planOpt.replicas | number : '1.0' }}
            {{ planOpt.replicas === 1 ? 'Replica' : 'Replicas' }}</span
          >
        </div>
      </div>
      <div>
        <h5 class="m-0">${{ calculatePlanMarkedUpPrice(planOpt) }}</h5>
        <div class="small no-wrap">/ month</div>
      </div>
    </div>
  </div>

  <div
    *ngIf="clusterScaleConfig"
    class="plan"
    [class.active]="isCustom"
    (click)="onPlanSelected(customPlan)">
    <div class="d-flex">
      <div class="radio">
        <div class="radio-inner"></div>
      </div>
      <div class="ms-3 d-flex justify-content-between w-100">
        <h5 class="m-0 lh-1">Custom</h5>
        <div *ngIf="isCustom">
          <h5 class="m-0">${{ calculatePlanMarkedUpPrice(plan) }}</h5>
          <div class="small no-wrap">/ month</div>
        </div>
      </div>
    </div>

    <div class="w-100" *ngIf="plan && clusterScaleConfig && isCustom">
      <div *ngIf="clusterScaleConfig.cpu && plan.cpu">
        <div class="bold">CPU</div>
        <div class="mt-2">
          <ngx-slider
            [value]="plan.cpu"
            [options]="cpuSliderOptions"
            (userChange)="onCpuChange($event)">
          </ngx-slider>
        </div>
      </div>

      <div *ngIf="clusterScaleConfig.ram && plan.ram">
        <h5 class="bold mt-4">RAM</h5>
        <div class="mt-2">
          <ngx-slider
            [value]="plan.ram"
            [options]="ramSliderOptions"
            (userChange)="onRamChange($event)">
          </ngx-slider>
        </div>
      </div>

      <div *ngIf="clusterScaleConfig.gpu && plan.gpu">
        <h5 class="bold mt-4">GPU</h5>
        <div class="mt-2">
          <ngx-slider
            [(value)]="plan.gpu"
            [options]="gpuSliderOptions"
            (valueChange)="onPlanValueChange()">
          </ngx-slider>
        </div>
      </div>

      <div *ngIf="clusterScaleConfig.storage && plan.storage">
        <h5 class="bold mt-4">Storage</h5>
        <div class="mt-2 small mb-4">
          <ngx-slider
            [(value)]="plan.storage"
            [options]="storageSliderOptions"
            (valueChange)="onPlanValueChange()">
          </ngx-slider>
        </div>
      </div>

      <div *ngIf="clusterScaleConfig.replicas && plan.replicas">
        <h5 class="bold mt-4">Replica Scale</h5>
        <div class="mt-2 small">
          <ngx-slider
            [(value)]="plan.replicas"
            [options]="replicasSliderOptions"
            (valueChange)="onPlanValueChange()">
          </ngx-slider>
        </div>
      </div>
    </div>
  </div>
</div>
