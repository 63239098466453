import { Injectable } from '@angular/core';

import { ConfigDto } from '../../models/config-dto';
import { HttpService } from './../http.service';

import {
  Capsule,
  Build,
  CapsuleType,
  Space,
  Cluster,
  Repo,
} from '../../models';
import { environment } from 'src/environments/environment';
import { CapsuleRequestDto } from '../../../create-content/pages/create-capsule/models';
import { ClusterPricing } from '../../models/cluster-pricing';
import { RepoService } from '../repo/repo.service';
import { ClusterNamespaceKeys } from '../../state/capsule';
import { ProductRequestDto } from 'src/app/create-content/pages/create-capsule/models/product-request.dto';

export enum CapsuleTypeEnum {
  backend = 'backend',
  frontend = 'frontend',
  docker = 'docker',
  mysql = 'mysql',
  mongo = 'mongo',
  postgresql = 'postgresql',
  redis = 'redis',
  storage = 'storage',
  wordpress = 'wordpress',
}

@Injectable({
  providedIn: 'root',
})
export class CapsuleService {
  constructor(
    private httpService: HttpService,
    private repoService: RepoService
  ) {}

  getTypeOfCapsule(capsule: Capsule): CapsuleType {
    let capsuleType = capsule.type;
    if (capsule.type === 'data') {
      capsuleType = capsule.jsonManifest.dbType;
    }

    return this.getCapsuleTypes().find((x) => x.capsuleApiKey === capsuleType);
  }

  getTypeIdOfCapsule(capsule: Capsule): string {
    if (capsule.type !== 'data') {
      return capsule.type;
    }
    const capsuleType = this.getCapsuleTypes().find(
      (ct) => ct.capsuleApiKey === capsule.jsonManifest.dbType
    );
    return capsuleType.id;
  }

  getCapsuleTypes(): CapsuleType[] {
    return [
      {
        id: 'backend',
        name: 'Backend',
        capsuleApiKey: 'backend',
        description: 'Node, Java, Go or Python deployments',
        imageUrl: '/assets/images/v2/icons/backend.svg',
      },
      {
        id: 'frontend',
        name: 'Frontend',
        capsuleApiKey: 'frontend',
        description: 'Angular, React, Vue or static site deployments',
        imageUrl: '/assets/images/v2/icons/frontend.svg',
      },
      {
        id: 'docker',
        name: 'Docker',
        capsuleApiKey: 'docker',
        description: 'Dockerfile based deployments',
        imageUrl: '/assets/images/v2/icons/docker.svg',
      },
      {
        id: 'wordpress',
        name: 'WordPress',
        capsuleApiKey: 'wordpress',
        description: 'Deploy a WordPress instance',
        imageUrl: '/assets/images/v2/icons/wordpress.svg',
        beta: true,
      },
      {
        id: 'mysql',
        name: 'MySQL',
        capsuleApiKey: 'mysql',
        description: 'MySQL Database',
        imageUrl: '/assets/images/v2/icons/mysql.svg',
      },
      {
        id: 'mongo',
        name: 'MongoDB',
        capsuleApiKey: 'MongoDb',
        description: 'MongoDB Database',
        imageUrl: '/assets/images/v2/icons/mongo.svg',
      },
      {
        id: 'postgresql',
        name: 'PostgreSQL',
        capsuleApiKey: 'PostgreSQL',
        description: 'PostgreSQL Database',
        imageUrl: '/assets/images/v2/icons/postgresql.svg',
      },
      {
        id: 'redis',
        name: 'Redis',
        capsuleApiKey: 'Redis',
        description: 'Redis In-memory Database',
        imageUrl: '/assets/images/v2/icons/redis.svg',
      },
      // {
      //   id: 'storage',
      //   name: 'Persistent Storage',
      //   capsuleApiKey: 'PersistentStorage',
      //   description: 'Cloud-Native files and folder storage',
      //   imageUrl: '/assets/images/v2/icons/data.svg',
      // },
      {
        id: 'storage',
        name: 'Persistent Storage',
        capsuleApiKey: 'PersistentStorageGanesha',
        description: 'Cloud-Native files and folder storage',
        imageUrl: '/assets/images/v2/icons/data.svg',
      },
      // {
      //   id: 'marketplace',
      //   name: 'Visit Marketplace',
      //   description: 'Find a public capsule for your project',
      //   imageUrl: '/assets/images/v2/logo-code-capsule-primary.svg',
      // },
    ];
  }

  public getCapsuleIcon(capsule: Capsule): string {
    if (capsule.type === 'data') {
      return this.getDataCapsuleType(capsule.jsonManifest);
    } else {
      return capsule.type;
    }
  }

  private getDataCapsuleType(jsonManifest: any): string {
    const type = jsonManifest.dbType.toLowerCase();
    const dataTypes = ['persistentstorage', 'persistentstorageganesha'];
    return dataTypes.includes(type) ? 'data' : type;
  }

  async getCapsuleById(space: Space, capsuleId: string): Promise<Capsule> {
    const clusterApi = space.cluster.clusterApiEndpoint;
    return this.httpService.get(`${clusterApi}/capsule/${capsuleId}`);
  }

  async getCapsuleBySlug(space: Space, slug: string): Promise<Capsule> {
    const capsules = await this.getCapsules(space);
    return capsules.find((capsule) => capsule.name === slug);
  }

  async getCapsules(space: Space): Promise<Capsule[]> {
    const response = await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/namespaces/${space.namespaceKey}/capsules`
    );
    return response.capsules;
  }

  async getCapsulesByTypes(space: Space, types: string[]): Promise<Capsule[]> {
    const queryString =
      `` +
      `filter={"types": [${types
        .map((x) => `"${x}"`)
        .join(', ')}], "include": []}`;
    const response = await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/namespaces/${space.namespaceKey}/capsules?${queryString}`
    );
    return response.capsules;
  }

  create(space: Space, capsuleRequest: CapsuleRequestDto): Promise<any> {
    const baseUrl = environment.apiBaseUrl;
    return this.httpService.post(`${baseUrl}/capsules/capsules`, {
      space,
      capsuleRequest,
    });
  }

  delete(space: Space, capsule: Capsule): Promise<any> {
    // TODO: This needs to hit API so the capsule can be soft deleted.
    return this.httpService.delete(
      `${space.cluster.clusterApiEndpoint}/namespaces/${space.namespaceKey}/capsules/${capsule.id}`
    );
  }

  update(endpoint, namespaceKey, capsule): Promise<any> {
    const baseUrl = environment.apiBaseUrl;
    return this.httpService.patch(`${baseUrl}/spaces/update-capsule`, {
      endpoint,
      namespaceKey,
      capsule,
    });
  }

  deploy(
    endpoint: string,
    capsuleId: string,
    buildId: string
  ): Promise<Capsule> {
    return this.httpService.post(
      `${endpoint}/capsules/${capsuleId}/deployments`,
      { buildId }
    );
  }

  newBuild(endpoint: string, capsuleId: string): Promise<Build> {
    return this.httpService.post(`${endpoint}/capsules/${capsuleId}/builds`, {
      capsuleId,
    });
  }

  getBuildLogs(space: Space, buildId: string): Promise<string> {
    return this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/builds/${buildId}/logs`
    );
  }

  async restart(space: Space, capsule: Capsule): Promise<void> {
    const replicaCount = capsule.replicas;
    await this.turnOff(space, capsule.id);
    await this.setReplicas(space, capsule.id, replicaCount);
  }

  async turnOn(space: Space, capsuleId: string): Promise<void> {
    return await this.httpService.patch(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsuleId}/replicas`,
      { replicas: 1 }
    );
  }

  async setReplicas(
    space: Space,
    capsuleId: string,
    replicaCount: number
  ): Promise<void> {
    return await this.httpService.patch(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsuleId}/replicas`,
      { replicas: replicaCount }
    );
  }

  async turnOff(space: Space, capsuleId: string): Promise<void> {
    return await this.httpService.patch(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsuleId}/replicas`,
      { replicas: 0 }
    );
  }

  async getBuildsByCapsuleId(
    space: Space,
    capsuleId: string
  ): Promise<Build[]> {
    const response = await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsuleId}/builds`
    );
    return response.builds;
  }

  async getCurrentDeployment(
    endpoint: string,
    capsuleId: string
  ): Promise<any> {
    const response = await this.httpService.get(
      `${endpoint}/capsules/${capsuleId}/deployment`
    );
    return response;
  }

  async getCapsuleLogs(
    space: Space,
    capsuleId: string,
    size: number,
    page: number
  ): Promise<string> {
    const queryString = `page=${page}&pageSize=${size}`;
    return this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsuleId}/logs?${queryString}`
    );
  }

  async getCapsuleMetrics(
    space: Space,
    capsuleId: string,
    metricsInterval: string
  ): Promise<string> {
    const startTime: Date = new Date();
    let interval = 100;
    switch (metricsInterval) {
      default:
      case 'last5Minutes':
        startTime.setMinutes(startTime.getMinutes() - 5);
        interval = 3;
        break;
      case 'last30Minutes':
        startTime.setMinutes(startTime.getMinutes() - 30);
        interval = 20;
        break;
      case 'last3Hours':
        startTime.setHours(startTime.getHours() - 3);
        interval = 280;
        break;
      case 'last3Days':
        startTime.setDate(startTime.getDate() - 3);
        interval = 4640;
        break;
      case 'last2Weeks':
        startTime.setDate(startTime.getDate() - 14);
        interval = 30320;
        break;
    }
    const endTime = new Date();
    const result = await this.httpService.get(
      `${
        space.cluster.clusterApiEndpoint
      }/capsules/${capsuleId}/metrics?start=${startTime.toISOString()}&end=${endTime.toISOString()}&intervalSeconds=${interval}`
    );
    return result.data;
  }

  async getCapsuleRepo(space: Space, capsuleId: string): Promise<any> {
    return this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsuleId}/repo`
    );
  }

  async getCapsulePushes(space: Space, capsuleId: string): Promise<any[]> {
    const response = await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsuleId}/pushes`
    );
    return response.capsulePushes;
  }

  async enableAutoBuild(
    space: Space,
    capsuleId: string,
    capsuleType: string
  ): Promise<void> {
    let capsuleTypeString = 'backend-capsule';
    if (capsuleType === 'deploy') {
      capsuleTypeString = 'frontend-capsule';
    }
    return this.httpService.patch(
      `${space.cluster.clusterApiEndpoint}/${capsuleTypeString}/${capsuleId}/should-auto-build`,
      { shouldAutoBuild: true }
    );
  }

  async disableAutoBuild(
    space: Space,
    capsuleId: string,
    capsuleType: string
  ): Promise<void> {
    let capsuleTypeString = 'backend-capsule';
    if (capsuleType === 'deploy') {
      capsuleTypeString = 'frontend-capsule';
    }
    return this.httpService.patch(
      `${space.cluster.clusterApiEndpoint}/${capsuleTypeString}/${capsuleId}/should-auto-build`,
      { shouldAutoBuild: false }
    );
  }

  async getCapsuleConfig(space: Space, capsule: Capsule): Promise<ConfigDto[]> {
    const response = await this.httpService.get(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsule.id}/configs`
    );
    return response.configs;
  }

  async setCapsuleConfig(
    space: Space,
    capsule: Capsule,
    configs: ConfigDto
  ): Promise<ConfigDto[]> {
    const response = await this.httpService.put(
      `${space.cluster.clusterApiEndpoint}/capsules/${capsule.id}/configs`,
      configs
    );
    return response.configs;
  }

  async patchCapsuleDescription(
    space: Space,
    capsule: Capsule,
    description: string
  ): Promise<Capsule> {
    const response = await this.httpService.patch(
      `${space.cluster.clusterApiEndpoint}/namespaces/${space.namespaceKey}/capsule/${capsule.id}`,
      {
        description: description,
      }
    );
    return response.capsule;
  }

  async patchCapsuleManifest(
    space: Space,
    capsule: Capsule,
    manifest: any
  ): Promise<Capsule> {
    const response = await this.httpService.patch(
      `${space.cluster.clusterApiEndpoint}/namespaces/${space.namespaceKey}/capsule/${capsule.id}/manifest`,
      manifest
    );
    return response.capsule;
  }

  async setCapsuleRepo(
    cluster: Cluster,
    capsuleId: string,
    branch: string,
    repo: Repo
  ): Promise<void> {
    const payloadRepo = this.repoService.getCapsuleApiRepoPayload({
      repo,
      branch,
      cluster,
    });
    return this.httpService.put(
      `${cluster.clusterApiEndpoint}/capsules/${capsuleId}/repo`,
      payloadRepo
    );
  }

  public updateCapsuleProducts(
    space: Space,
    capsule: Capsule,
    products: ProductRequestDto
  ): Promise<Capsule> {
    const clusterApiEndpoint = space.cluster.clusterApiEndpoint;
    const capsuleId = capsule.id;
    const endpoint = `${clusterApiEndpoint}/capsules/${capsuleId}/products`;
    return this.httpService.patch(endpoint, products);
  }

  async getClusterConfiguredProducts(
    cluster: Cluster
  ): Promise<ClusterPricing> {
    return this.httpService.get(
      `${cluster.clusterApiEndpoint}/configured-products`
    );
  }

  public async getCapsulesForSpaces(spaces: Space[]): Promise<Capsule[]> {
    const clusterNamespaceKeys = this.getClustersNamespaceKeysFor(spaces);
    const capsules: Capsule[] = [];

    for (const clusterNamespaces of clusterNamespaceKeys) {
      const clusterUrl = clusterNamespaces.clusterUrl;
      const namespaceKeys = JSON.stringify({
        namespaceKeys: clusterNamespaces.namespaceKeys,
      });
      const endpoint = `${clusterUrl}/namespaces/capsules?namespaces=${namespaceKeys}`;
      const response = await this.httpService.get(endpoint);
      capsules.push(...(response.capsules || []));
    }

    return capsules;
  }

  private getClustersNamespaceKeysFor(spaces: Space[]): ClusterNamespaceKeys[] {
    const clusterUrls = spaces.map((x) => x.cluster.clusterApiEndpoint);
    const uniqueClusterUrls = [...new Set(clusterUrls)];

    return uniqueClusterUrls.map((clusterUrl) => ({
      clusterUrl,
      namespaceKeys: spaces
        .filter((x) => x.cluster.clusterApiEndpoint === clusterUrl)
        .map((x) => x.namespaceKey),
    }));
  }
}
