.rounded-lg {
  border-radius: 0.3rem;
}

.log-window {
  position: relative;

  .no-overflow {
    white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }

  .log-overflow {
    overflow: visible;
    white-space: normal;
    word-break: keep-all;
    word-wrap: break-word;
    transition: overflow 0.15s ease-out;
  }

  .loading-spinner {
    position: absolute;
    top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .log-caret {
    transition: 0.3s all;
  }

  .log-caret-rotated {
    transform: rotate(90deg);
    transition: rotation 0.15s ease-out;
  }

  .log-dot {
    background: #a19200;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 10rem;
  }

  .date {
    color: #a19200;
    font-family: monospace;
    word-break: keep-all;
    white-space: pre;
  }

  .time {
    color: #ffdf00;
    //color: #a19200;
    font-family: monospace;
    word-break: keep-all;
  }

  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  //.info-log{
  //  color: #e1e1e1;
  //}
  //
  //.error-log{
  //  color: #b6b6b6;
  //}

  /* width */
  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-corner {
    background: black;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
    border-left: 1px solid #3d3d3d;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  ::-webkit-scrollbar-track:horizontal {
    background: #000000;
    border-top: 1px solid #3d3d3d;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to right,
        #3d3d3d,
        black,
        black,
        black,
        #ffdf00,
        #ffdf00,
        #ffdf00,
        #ffdf00,
        black,
        black,
        black);
    border-left: 1px solid #3d3d3d;
    border-right: 1px solid black;
    width: 10px;
    left: 5px;
    opacity: 0.7;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: linear-gradient(to bottom,
        #3d3d3d,
        black,
        black,
        black,
        #ffdf00,
        #ffdf00,
        #ffdf00,
        #ffdf00,
        black,
        black,
        black);
    border-top: 1px solid #3d3d3d;
    border-bottom: 1px solid black;
    border-left: none;
    border-right: none;
    height: 10px;
    bottom: 5px;
    opacity: 0.7;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to right,
        #3d3d3d,
        black,
        black,
        black,
        #a19200,
        #a19200,
        #a19200,
        #a19200,
        black,
        black,
        black);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:horizontal:hover {
    background: linear-gradient(to bottom,
        #3d3d3d,
        black,
        black,
        black,
        #a19200,
        #a19200,
        #a19200,
        #a19200,
        black,
        black,
        black);
  }

  .bg-black {
    background: black;
  }

  .bottom-bar {
    border-top: 1px solid #3d3d3d;
  }

  .scroll-to-bottom {
    color: #a0a0a0;
  }
}

//pre code.hljs {
//  display: block;
//  overflow-x: auto;
//  padding: 1em
//}
//
//code.hljs {
//  padding: 3px 5px
//}

.hljs {
  color: #a9b7c6;
  //background: #282b2e;
}

.hljs-number,
.hljs-literal,
.hljs-symbol,
.hljs-bullet {
  color: #6897bb;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-deletion {
  color: #cc7832;
}

.hljs-variable,
.hljs-template-variable,
.hljs-link {
  color: #629755;
}

.hljs-comment,
.hljs-quote {
  color: #808080;
}

.hljs-meta {
  color: #bbb529;
}

.hljs-string,
.hljs-attribute,
.hljs-addition {
  color: #6a8759;
}

.hljs-section,
.hljs-title,
.hljs-type {
  color: #ffc66d;
}

.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #e8bf6a;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
