import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { Capsule, Cluster, ClusterPricing, Plan, Space, Team } from '../models';
import { UserState, selectUser } from '../state/user';
import { selectAllTeams } from '../state/team';
import { selectAllSpaces } from '../state/space';
import { selectAllCapsules } from '../state/capsule';
import { selectAllClusters } from '../state/cluster';
import { UsageMarkupService } from './usage-markup.service';

@Injectable()
export class CapsulePricingService {
  private userState: UserState;
  private teams: Team[] = [];
  private spaces: Space[] = [];
  private clusters: Cluster[] = [];
  private capsules: Capsule[] = [];

  constructor(private store: Store, private markupService: UsageMarkupService) {
    const user$ = this.store.select(selectUser);
    const teams$ = this.store.select(selectAllTeams);
    const spaces$ = this.store.select(selectAllSpaces);
    const clusters$ = this.store.select(selectAllClusters);
    const capsules$ = this.store.select(selectAllCapsules);

    user$.subscribe((state) => (this.userState = state));
    teams$.subscribe((teams) => (this.teams = teams));
    spaces$.subscribe((spaces) => (this.spaces = spaces));
    clusters$.subscribe((clusters) => (this.clusters = clusters));
    capsules$.subscribe((capsules) => (this.capsules = capsules));
  }

  public shouldRequestCredit(
    pricing: ClusterPricing,
    plan: Plan,
    team: Team
  ): boolean {
    if (
      !plan ||
      !pricing ||
      !this.userState ||
      this.userState.canScaleCapsulesWithoutCredit
    ) {
      return false;
    }

    let capsulePrice = this.calculateCapsulePrice(pricing, plan);
    capsulePrice = this.markupService.applyMarkupForTeam(capsulePrice, team);

    const creditThreshold = this.userState.capsuleScaleWithoutCreditLimit;
    return creditThreshold <= capsulePrice - this.userState.credit;
  }

  public getUserCapsulesTotalCost(options?: {
    includeMarkup: boolean;
  }): number {
    const userCapsules = this.getCapsulesOwnedByUser();
    let total = 0;
    for (const capsule of userCapsules) {
      let capsuleCost = (total += this.getCapsuleCost(capsule));

      if (options?.includeMarkup) {
        const team = this.getCapsuleTeam(capsule);
        capsuleCost = this.markupService.applyMarkupForTeam(capsuleCost, team);
      }

      total += capsuleCost;
    }
    return total;
  }

  private getCapsuleTeam(capsule): Team {
    return this.teams.find((x) => x.id === capsule.space?.team?.id);
  }

  private getCapsuleCost(capsule: Capsule): number {
    const plan = Plan.fromCapsuleProducts(capsule.products);
    const pricing = this.getCapsuleClusterPricing(capsule);

    return this.calculateCapsulePrice(pricing, plan);
  }

  private getCapsuleClusterPricing(capsule: Capsule): ClusterPricing {
    const namespaceKey = capsule.namespace.key;
    const space = this.spaces.find((x) => x.namespaceKey == namespaceKey);
    const cluster = this.clusters.find((x) => x.id === space.clusterId);
    return cluster.pricing;
  }

  private getCapsulesOwnedByUser(): Capsule[] {
    const user = this.userState.currentUser;
    const userTeams = this.teams.filter((x) => x.owner.id === user.id);
    const teamIds = userTeams.map((x) => x.id);
    const userSpaces = this.spaces.filter((x) => teamIds.includes(x.teamId));
    const namespaceKeys = userSpaces.map((x) => x.namespaceKey);
    return this.capsules.filter((x) => namespaceKeys.includes(x.namespace.key));
  }

  public calculateCapsulePrice(pricing: ClusterPricing, plan: Plan): number {
    if (!pricing || !plan) return 0;

    const cpuCost = this.calculateCpuCost(pricing, plan);
    const ramCost = this.calculateRamCost(pricing, plan);
    const gpuCost = this.calculateGpuCost(pricing, plan);
    const storageCost = this.calculateStorageCost(pricing, plan);

    let total = cpuCost + ramCost + gpuCost + storageCost;
    if (plan && plan.replicas) {
      total = total * plan.replicas;
    }
    return total;
  }

  public calculateCpuCost(pricing: ClusterPricing, plan: Plan): number {
    if (!pricing || !plan || !plan.cpu) return 0;
    return (plan.cpu / pricing.cpuProduct.unitRate) * pricing.cpuProduct.price;
  }

  public calculateRamCost(pricing: ClusterPricing, plan: Plan): number {
    if (!pricing || !plan || !plan.ram) return 0;
    return (plan.ram / pricing.ramProduct.unitRate) * pricing.ramProduct.price;
  }

  public calculateGpuCost(pricing: ClusterPricing, plan: Plan): number {
    if (!pricing || !plan || !plan.gpu) return 0;
    return (plan.gpu / pricing.gpuProduct.unitRate) * pricing.gpuProduct.price;
  }

  public calculateStorageCost(pricing: ClusterPricing, plan: Plan): number {
    if (!pricing || !plan || !plan.storage) return 0;
    return (
      (plan.storage / pricing.storageProduct.unitRate) *
      pricing.storageProduct.price
    );
  }
}
