<ng-select
  class="rounded"
  placeholder="Select your Storage Capsule"
  [items]="capsules"
  bindValue="id"
  [searchable]="false"
  [ngModel]="capsule?.id"
  (ngModelChange)="onCapsuleIdChange($event)">
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    <div class="d-flex align-items-center">
      <div class="icon">
        <img src="/assets/images/v2/icons/data.svg" class="me-2" alt="icon" />
      </div>
      <div>
        <div class="mb-1">
          {{ item.description }}
        </div>

        <div class="text-secondary small lh-1">
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-select>
