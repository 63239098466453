import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, map, Subscription } from 'rxjs';

import { Capsule, Space } from 'src/app/core/models';
import { selectAllCapsules } from 'src/app/core/state/capsule';
import { selectAllSpaces } from 'src/app/core/state/space';

@Component({
  selector: 'app-select-mysql-capsule',
  templateUrl: './select-mysql-capsule.component.html',
  styleUrls: ['./select-mysql-capsule.component.scss'],
})
export class SelectMysqlCapsuleComponent implements OnInit, OnDestroy {
  @Input() public capsule: Capsule;
  @Output() public capsuleChange = new EventEmitter<Capsule>();
  @Output() public change = new EventEmitter<Capsule>();

  public capsules: Capsule[] = [];
  public capsuleSpaceMap: { [capsuleId: string]: Space } = {};

  private capsules$ = this.store.select(selectAllCapsules);
  private spaces$ = this.store.select(selectAllSpaces);

  private subs: Subscription[] = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    const sub = this.capsules$
      .pipe(
        combineLatestWith(this.spaces$),
        map(([capsules, spaces]) => ({ capsules, spaces }))
      )
      .subscribe(({ capsules, spaces }) => {
        let mysqlCapsules = capsules.filter(
          (x) => x.jsonManifest.dbType == 'mysql'
        );

        this.capsuleSpaceMap = {};
        for (const capsule of mysqlCapsules) {
          this.capsuleSpaceMap[capsule.id] = spaces.find(
            (s) => s.namespaceKey == capsule.namespace.key
          );
        }
        this.capsules = mysqlCapsules;
      });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) sub.unsubscribe();
  }

  public getSpaceName(capsule: Capsule): string {
    return this.capsuleSpaceMap[capsule.id]?.name ?? '';
  }
  public getTeamName(capsule: Capsule): string {
    return this.capsuleSpaceMap[capsule.id]?.team.name ?? '';
  }
  public getCountryCode(capsule: Capsule): string {
    return this.capsuleSpaceMap[capsule.id]?.cluster?.region?.countryCode ?? '';
  }

  onCapsuleIdChange(capsuleId: string): void {
    const capsule = this.capsules.find((x) => x.id == capsuleId);
    this.capsuleChange.emit(capsule);
    this.change.emit(capsule);
  }
}
