import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import {
  Plan,
  CapsuleType,
  ClusterPricing,
  ClusterScaleConfig,
  Team,
} from 'src/app/core/models';

import { CapsulePricingService } from '../../services/capsule-pricing.service';
import { UsageMarkupService } from '../../services/usage-markup.service';

@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss'],
})
export class ProductSummaryComponent implements OnChanges {
  @Input()
  capsuleType: CapsuleType;

  @Input()
  plan: Plan;

  @Input()
  clusterPricing: ClusterPricing;

  @Input()
  clusterScaleConfig?: ClusterScaleConfig;

  @Input()
  team: Team;

  @Input()
  buttonText: string;

  @Input()
  productName = 'Test Product';

  @Input()
  faButtonIcon = 'fa fa-solid fa-arrow-right';

  cpuCost: number = 0;
  ramCost: number = 0;
  gpuCost: number = 0;
  storageCost: number = 0;
  totalCost: number = 0;

  @Output() buttonPress = new EventEmitter();

  constructor(
    private pricing: CapsulePricingService,
    private markupService: UsageMarkupService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.cpuCost = 0;
    this.ramCost = 0;
    this.gpuCost = 0;
    this.storageCost = 0;
    this.totalCost = 0;

    if (this.clusterPricing && this.plan && this.team) {
      this.calculateTotal();
    }
  }

  private calculateTotal(): void {
    const pricing = this.clusterPricing;
    const plan = this.plan;
    const team = this.team;

    const cpuCost = this.pricing.calculateCpuCost(pricing, plan);
    const ramCost = this.pricing.calculateRamCost(pricing, plan);
    const gpuCost = this.pricing.calculateGpuCost(pricing, plan);
    const storageCost = this.pricing.calculateStorageCost(pricing, plan);
    const totalCost = this.pricing.calculateCapsulePrice(pricing, plan);

    this.cpuCost = this.markupService.applyMarkupForTeam(cpuCost, team);
    this.ramCost = this.markupService.applyMarkupForTeam(ramCost, team);
    this.gpuCost = this.markupService.applyMarkupForTeam(gpuCost, team);
    this.storageCost = this.markupService.applyMarkupForTeam(storageCost, team);
    this.totalCost = this.markupService.applyMarkupForTeam(totalCost, team);
  }
}
