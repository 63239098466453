import { Injectable } from '@angular/core';

import { Team } from '../models';

@Injectable()
export class UsageMarkupService {
  public applyMarkupForTeam(value: number, team: Team): number {
    const markupPercent = this.getMarkupPercentForTeam(team);
    return Number(value) * (1 + markupPercent / 100);
  }

  private getMarkupPercentForTeam(team: Team): number {
    const teamAgency = team?.agency?.teamAgency;
    if (!teamAgency) return 0;
    return teamAgency.markupPercent;
  }
}
