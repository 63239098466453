<ng-select
  class="rounded"
  placeholder="Select your MySql Capsule"
  [items]="capsules"
  bindValue="id"
  [searchable]="false"
  [ngModel]="capsule?.id"
  (ngModelChange)="onCapsuleIdChange($event)">
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    <div class="d-flex align-items-center">
      <div class="icon">
        <img src="/assets/images/v2/icons/mysql.svg" class="me-2" alt="icon" />
      </div>
      <div>
        <div class="mb-1">
          {{ item.description }}
        </div>

        <div class="text-secondary small d-flex align-items-center lh-1">
          <div class="thumbnail icon-team"></div>
          <div class="ms-2">{{ getTeamName(item) }}</div>

          <div class="mx-2">/</div>

          <div
            class="thumbnail fi fis fi-{{
              getCountryCode(item) | lowercase
            }}"></div>
          <div class="ms-2">{{ getSpaceName(item) }}</div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-select>
