<ng-select
  class="rounded"
  [placeholder]="
    customPlaceholder ? customPlaceholder : 'Search/Select a Cloud'
  "
  [items]="pClusters"
  bindLabel="region.name"
  [searchable]="!selected"
  [(ngModel)]="selected"
  (ngModelChange)="selectCloud()">
  <ng-template ng-option-tmp ng-label-tmp let-item="item">
    <app-select-three-split-option
      [title]="item.region?.name"
      [subTitle]="item.name"
      [countryCode]="item.region?.countryCode | lowercase">
    </app-select-three-split-option>
  </ng-template>
</ng-select>
