import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatestWith, map, Subscription } from 'rxjs';

import { Capsule, Space } from 'src/app/core/models';
import { selectAllCapsules } from 'src/app/core/state/capsule';
import { selectAllSpaces } from 'src/app/core/state/space';

@Component({
  selector: 'app-select-storage-capsule',
  templateUrl: './select-storage-capsule.component.html',
  styleUrls: ['./select-storage-capsule.component.scss'],
})
export class SelectStorageCapsuleComponent implements OnInit, OnDestroy {
  @Input() public space: Space;
  @Input() public capsule: Capsule;
  @Output() public capsuleChange = new EventEmitter<Capsule>();
  @Output() public change = new EventEmitter<Capsule>();

  public capsules: Capsule[] = [];

  private capsules$ = this.store.select(selectAllCapsules);
  private spaces$ = this.store.select(selectAllSpaces);

  private subs: Subscription[] = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    const sub = this.capsules$
      .pipe(
        combineLatestWith(this.spaces$),
        map(([capsules, spaces]) => ({ capsules, spaces }))
      )
      .subscribe(({ capsules, spaces }) => {
        this.capsules = capsules.filter((x) => {
          const space = spaces.find((s) => s.namespaceKey == x.namespace.key);
          return (
            x.jsonManifest.dbType == 'PersistentStorageGanesha' &&
            space?.namespaceKey == this.space?.namespaceKey
          );
        });
      });

    this.subs.push(sub);
  }

  ngOnDestroy(): void {
    for (const sub of this.subs) sub.unsubscribe();
  }

  onCapsuleIdChange(capsuleId: string): void {
    const capsule = this.capsules.find((x) => x.id == capsuleId);
    this.capsuleChange.emit(capsule);
    this.change.emit(capsule);
  }
}
